<template>
  <div>
    <breadCrumb :title="$t('suo-you-pin-pai')" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="rounded bg-white px-3 pt-3 shadow-sm">
          <div class="row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-2 gutters-10">
            <div class="col text-center" v-for="(item, i) in companyList" :key="i" @click="toDetail(item)">
              <a  class="d-block border-light hov-shadow-md mb-3 rounded border p-3">
                <img :src="item.url" class="lazyload h-70px mw-100 mx-auto">
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: this.$t('suo-you-pin-pai'),
          path: ''
        },
      ],
      companyList: [
        {
          url: require('../../assets/imgs/category1.png'),
          key: '1'
        },
        {
          url: require('../../assets/imgs/category2.png'),
           key: '2'
        },
        {
          url: require('../../assets/imgs/category3.png'),
           key: '3'
        },
        {
          url: require('../../assets/imgs/category4.jpg')
        },
        {
          url: require('../../assets/imgs/category5.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
        {
          url: require('../../assets/imgs/category1.png')
        },
      ]
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDetail(data) {
      this.$router.push({
        name: 'categoryDetail',
        query: {
          company: data.key
        }
      })
    }
  }
}
</script>